import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
import Login from "@/components/Login/index.vue";
import Register from "@/components/Register/index.vue";
import Footer from "@/components/Footer/index";
import VideoPayment from "@/views/video/cpns/pricePopUp.vue";
import GlobalMenu from "@/components/GlobalMenu/index.vue";
import Loader from "@/components/Loader/index.vue";
import UseStoreCommon from "@/store/modules/common";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { useRoute } from "vue-router";
import { watch, ref } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    const storeCommon = UseStoreCommon();
    const route = useRoute();
    let hideFooterPath = [{
      path: "/exam"
    }, {
      path: "/user/job/content"
    }, {
      path: "/book"
    }];
    let footerHide = ref(false);
    watch(() => route.path, path => {
      footerHide.value = hideFooterPath.findIndex(item => {
        return item.path == route.path;
      });
    }, {
      deep: true,
      immediate: true
    });

    // let res =
    //   console.log('12311');
    //   console.log(route.path);
    //   console.log(item.path);
    //
    // })
    // console.log(res);

    //

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        class: "wrapper",
        style: _normalizeStyle(`padding-bottom: ${_unref(footerHide) < 0 ? '120' : '0'}px;`)
      }, [_createVNode(_unref(ElConfigProvider), {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(GlobalMenu), _createVNode(_component_router_view), _unref(footerHide) < 0 ? (_openBlock(), _createBlock(_unref(Footer), {
          key: 0
        })) : _createCommentVNode("", true), _createVNode(Login), _createVNode(Register), _createVNode(VideoPayment), _unref(storeCommon).loaderToggle ? (_openBlock(), _createBlock(Loader, {
          key: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["locale"])], 4);
    };
  }
};