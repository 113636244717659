import { defineStore } from "pinia";
import { requestSearchList, requestWxPay, requestZfbPay, requestPayStatus, requestBalancePay, requestCouponList, requestGradesAndSubjects, requestGrades, requestGradeSection, requestSubjects, requestSearchUserRecord, requestSearchListDelete, requestSearchListDeleteAll, requestFormulaList, requestFormulaCollectAdd, requestFormulaCollectDel, requestEntryList, requestEntryCollectAdd, requestEntryCollectDel, requestSymbolList } from "@/service/modular/common";
const UseStoreCommon = defineStore("storeCommon", {
  state: () => ({
    menuShow: false,
    searchData: [],
    searchRecord: [],
    loaderToggle: false,
    gradesAndSubjects: [],
    grades: [],
    gradeSection: [],
    subjects: [],
    formula: {
      list: [],
      total: 0
    },
    entry: {
      list: [],
      total: 0
    },
    symbol: {
      list: []
    },
    bookCatalogId: "",
    editTag: {},
    //单个注释内容
    dictionaryStatus: false,
    //词典状打开状态
    selectedFavoriteList: [] //收藏标注列表
  }),

  actions: {
    // 模糊搜索
    async requestSearchListAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestSearchList(parameter);
        if (res.code == 200) {
          this.searchData = res.data;
          resolve(true);
        }
      });
    },
    // 分页查询用户搜索记录
    requestSearchUserRecordAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestSearchUserRecord(parameter);
        if (res.code == 200) {
          this.searchRecord = res.data.list;
          resolve(true);
        }
      });
    },
    //删除用户搜索记录通过记录id
    requestSearchListDeleteAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestSearchListDelete(parameter);
        if (res.code == 200) {
          resolve(true);
        }
      });
    },
    //清空用户搜索记录通过用户id
    requestSearchListDeleteAllAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestSearchListDeleteAll(parameter);
        if (res.code == 200) {
          resolve(true);
        }
      });
    },
    // 微信接口
    async requestWxPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestWxPay(parameter);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    // 支付宝接口
    async requestPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestZfbPay(parameter);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    // 余额支付
    async requestBalancePayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestBalancePay(parameter);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    // 订单状态
    async requestPayStatusAction(orderNumber) {
      return new Promise(async (resolve, reject) => {
        let res = await requestPayStatus(orderNumber);
        if (res.code == 200 && res.data) {
          resolve(res.data);
        }
      });
    },
    // 优惠劵
    async requestCouponListAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestCouponList(parameter);
        if (res.code == 200 && res.data) {
          resolve(res.data);
        }
      });
    },
    // 获取年级和学科
    async requestGradesAndSubjectsAction(parameter) {
      let res = await requestGradesAndSubjects(parameter);
      if (res.code == 200) {
        this.gradesAndSubjects = res.data;
      }
    },
    // 获取年级
    async requestGradesAction() {
      let res = await requestGrades();
      console.log(res);
      if (res.code == 200) {
        this.grades = res.data;
      }
    },
    // 获取年级段
    async requestGradeSectionAction(gradeId) {
      let res = await requestGradeSection(gradeId);
      if (res.code == 200) {
        this.gradeSection = res.data;
      }
    },
    //获取学科
    async requestSubjectsAction(gradeId) {
      let res = await requestSubjects(gradeId);
      if (res.code == 200) {
        this.subjects = res.data;
      }
    },
    // 按搜索条件查询公式模版
    async requestFormulaListAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestFormulaList(parameter);
        if (res.code == 200) {
          this.formula.list = res.data.list;
          this.formula.total = res.data.total;
          resolve(true);
        }
      });
    },
    // 用户收藏公式-添加
    requestFormulaCollectAddAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestFormulaCollectAdd(parameter);
        if (res.code == 200) {
          resolve(true);
          ElMessage({
            message: "收藏成功",
            type: "success"
          });
        }
      });
    },
    // 用户收藏公式-删除
    requestFormulaCollectDelAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestFormulaCollectDel(parameter);
        if (res.code == 200) {
          resolve(true);
          ElMessage({
            message: "取消成功",
            type: "success"
          });
        }
      });
    },
    // 查询词条
    async requestEntryListAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestEntryList(parameter);
        if (res.code == 200) {
          this.entry.list = res.data.records;
          this.entry.total = res.data.total;
          resolve(true);
        }
      });
    },
    // 用户收藏词条-添加
    requestEntryCollectAddAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestEntryCollectAdd(parameter);
        if (res.code == 200) {
          resolve(true);
          ElMessage({
            message: "收藏成功",
            type: "success"
          });
        }
      });
    },
    // 用户收藏词条-删除
    requestEntryCollectDelAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestEntryCollectDel(parameter);
        if (res.code == 200) {
          resolve(true);
          ElMessage({
            message: "取消成功",
            type: "success"
          });
        }
      });
    },
    async requestSymbolListAction() {
      // return new Promise(async (resolve, reject) => {})
      let res = await requestSymbolList();
      if (res.code == 200) {
        this.symbol.list = res.data;
      }
    }
  }
});
export default UseStoreCommon;