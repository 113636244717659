import LysRequest from "../request/index"

// 验证用户账号是否重复登录
export const requestRepeatLogin = () => {
  return LysRequest.request({
    url: `/user/infoByToken`,
    method: "post"
  })
}

// 发送手机验证码
export const requestMobileCode = (data) => {
  return LysRequest.request({
    url: `/user/sendMobileCode`,
    method: "post",
    data
  })
}

// 验证码是否正确
export const requestMobileCodeCorrect = (data) => {
  return LysRequest.request({
    url: "/user/getMobileCodeCorrect",
    method: "post",
    data
  })
}

// 登录
export const requestLogin = (data) => {
  return LysRequest.request({
    url: "/user/loginByPassword",
    method: "post",
    data
  })
}

// 手机验证码登录
export const requestPhoneLogin = (data) => {
  return LysRequest.request({
    url: "/user/loginByMobileCode",
    method: "post",
    data
  })
}

// 退出登录
export const requestLogout = (data) => {
  return LysRequest.request({
    url: "/user/loginOutByUserId",
    method: "post",
    data
  })
}

// 注册
export const requestregister = (data) => {
  return LysRequest.request({
    url: "/user/createByPhone",
    method: "post",
    data
  })
}

// 获取个人信息
export const requestUserInfo = () => {
  return LysRequest.request({
    url: "/user/infoByToken",
    method: "post"
  })
}

// 账号是否存在
export const requestUserExist = (userName) => {
  return LysRequest.request({
    url: `/user/isExist/${userName}`,
    method: "get"
  })
}

// 修改密码通过手机号
export const requestUpdatePassword = (data) => {
  return LysRequest.request({
    url: `/user/modifyPasswordByPhone`,
    method: "post",
    data
  })
}

// 注销
export const requestUserCancel = (data) => {
  return LysRequest.request({
    url: `/user/cancelByMobileCode`,
    method: "post",
    data
  })
}
