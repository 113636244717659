import { createRouter, createWebHashHistory } from "vue-router";
import UsestoreLogin from "@/store/modules/login";
import UseStoreUser from "@/store/modules/user";
const routes = [{
  path: "/",
  redirect: "/home",
  children: [{
    path: "/home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      name: "首页"
    }
  }, {
    path: "/topicRecommend",
    component: () => import("@/views/answer/recommend.vue"),
    meta: {
      name: "每日推荐(智库)"
    }
  }, {
    path: "/notifications",
    component: () => import("@/views/notifications/index.vue"),
    meta: {
      name: "信息通知"
    }
  }, {
    path: "/videoCore",
    component: () => import("@/views/video/videoCore.vue"),
    meta: {
      name: "视频中心"
    }
  }, {
    path: "/videoRecommend",
    component: () => import("@/views/video/recommend.vue"),
    meta: {
      name: "每日推荐(视频)"
    }
  }, {
    path: "/videoCollect",
    component: () => import("@/views/video/collect.vue"),
    meta: {
      name: "我的收藏(视频)"
    }
  }, {
    path: "/details",
    component: () => import("@/views/details/index.vue"),
    meta: {
      name: "课本详情"
    }
  }, {
    path: "/goods/details",
    component: () => import("@/views/goods/details.vue"),
    meta: {
      name: "商品详情"
    }
  }, {
    path: "/video",
    component: () => import("@/views/video/index.vue"),
    meta: {
      name: "视频详情"
    }
  }, {
    path: "/myOrder",
    component: () => import("@/views/myOrder/index.vue"),
    meta: {
      name: "我的订单"
    }
  }, {
    path: "/activity",
    component: () => import("@/views/activity/index.vue")
  }, {
    path: "/addVideo",
    component: () => import("@/views/video/addVideo.vue"),
    meta: {
      name: "添加视频"
    }
  }, {
    path: "/videoNav",
    component: () => import("@/views/video/videoNav.vue"),
    meta: {
      name: "创作中心"
    }
  }, {
    path: "/videoReview",
    component: () => import("@/views/video/videoReview.vue"),
    meta: {
      name: "审核中心"
    }
  }, {
    path: "/myVideo",
    component: () => import("@/views/video/myVideo.vue"),
    meta: {
      name: "我的视频"
    }
  }, {
    path: "/question",
    name: "question",
    component: () => import("@/views/question/index.vue"),
    meta: {
      name: "创作中心"
    }
  }, {
    path: "/addCourses",
    component: () => import("@/views/question/addCourses.vue"),
    meta: {
      name: "添加题目"
    }
  }, {
    path: "/examination",
    component: () => import("@/views/question/examination.vue"),
    meta: {
      name: "智能测评"
    }
  }, {
    path: "/examination/examGrade",
    component: () => import("@/views/question/examGrade.vue"),
    meta: {
      name: "测评成绩"
    }
  }, {
    path: "/exam",
    component: () => import("@/views/question/exam.vue"),
    meta: {
      name: "测评考试"
    }
  }, {
    path: "/reviewCourses",
    component: () => import("@/views/question/reviewCourses.vue"),
    meta: {
      name: "题库-审核中心"
    }
  }, {
    path: "/myCourses",
    component: () => import("@/views/question/myCourses.vue"),
    meta: {
      name: "我的题库"
    }
  }, {
    path: "/assignment",
    component: () => import("@/views/question/assignment.vue"),
    meta: {
      name: "原题招募"
    }
  }, {
    path: "/shop",
    component: () => import("@/views/shop/index.vue"),
    meta: {
      name: "开发中"
    }
  }, {
    path: "/code",
    component: () => import("@/views/code/index.vue"),
    meta: {
      name: "开发中"
    }
  }]
}, {
  path: "/topicCore",
  component: () => import("@/views/answer/topicCore.vue"),
  meta: {
    name: "题库中心"
  }
}, {
  path: "/answer",
  component: () => import("@/views/answer/index.vue"),
  meta: {
    name: "题目详情"
  }
}, {
  path: "/myWallet",
  component: () => import("@/views/myWallet/index.vue"),
  meta: {
    name: "我的钱包"
  }
}, {
  path: "/vip",
  component: () => import("@/views/vip/index.vue"),
  meta: {
    name: "会员"
  }
},
// 用户中心
{
  path: "/user",
  component: () => import("@/views/user/index.vue"),
  redirect: "/user/personage",
  children: [{
    path: "personage",
    component: () => import("@/views/user/user-personage"),
    redirect: "/user/personage/information",
    children: [{
      path: "information",
      component: () => import("@/views/user/user-personage/information/index.vue")
    }, {
      path: "classSchedule",
      component: () => import("@/views/user/user-personage/classSchedule/index.vue")
    }, {
      path: "coursesShop",
      component: () => import("@/views/user/user-personage/coursesShop/index.vue")
    }, {
      path: "chitchat",
      component: () => import("@/views/user/user-personage/chitchat/index.vue")
    }]
  }, {
    path: "course",
    component: () => import("@/views/user/user-course/"),
    redirect: "/user/course/inside",
    children: [{
      path: "inside",
      component: () => import("@/views/user/user-course/inside/index.vue")
    }, {
      path: "outside",
      component: () => import("@/views/user/user-course/outside/index.vue")
    }, {
      path: "exercises",
      component: () => import("@/views/user/user-course/exercises/index.vue")
    }]
  }, {
    path: "note",
    component: () => import("@/views/user/user-note/"),
    redirect: "/user/note/user-note",
    children: [{
      path: "topic-note",
      component: () => import("@/views/user/user-note/topic-note/index.vue")
    }, {
      path: "user-note",
      component: () => import("@/views/user/user-note/user-note/index.vue")
    }, {
      path: "books-note",
      component: () => import("@/views/user/user-note/books-note/index.vue")
    }, {
      path: "video-note",
      component: () => import("@/views/user/user-note/video-note/index.vue")
    }, {
      path: "recording",
      component: () => import("@/views/user/user-note/recording/index.vue")
    }]
  }, {
    path: "collect",
    component: () => import("@/views/user/user-collect/"),
    redirect: "/user/collect/books-collect",
    children: [{
      path: "books-collect",
      component: () => import("@/views/user/user-collect/books/index.vue")
    }, {
      path: "topic-collect",
      component: () => import("@/views/user/user-collect/topic/index.vue")
    }, {
      path: "video-collect",
      component: () => import("@/views/user/user-collect/video/index.vue")
    }]
  }, {
    path: "wrong",
    component: () => import("@/views/user/user-wrong/"),
    redirect: "/user/wrong/topic-wrong",
    children: [{
      path: "topic-wrong",
      component: () => import("@/views/user/user-wrong/topic/index.vue")
    }]
  }, {
    path: "records",
    component: () => import("@/views/user/user-records/index.vue"),
    redirect: "/user/records/books-records",
    children: [{
      path: "books-records",
      component: () => import("@/views/user/user-records/books/index.vue")
    }, {
      path: "topic-records",
      component: () => import("@/views/user/user-records/topic/index.vue")
    }, {
      path: "video-records",
      component: () => import("@/views/user/user-records/video/index.vue")
    }]
  }, {
    path: "/user/job",
    component: () => import("@/views/user/user-job/index.vue")
  }]
}, {
  path: "/user/job/content",
  component: () => import("@/views/user/user-job/jobContent.vue")
}, {
  path: "/user/job/jobResult",
  component: () => import("@/views/user/user-job/jobResult.vue")
},
// 乐学模块
// {
//   path:"/happyLearning",
//   redirect:"/happyLearning/lute",
//   children:[
//     {
//       path:"lute",
//       component:()=> import("@/views/happyLearning/page/lute/index.vue")
//     },

//     {
//       path:"chess",
//       component:()=> import("@/views/happyLearning/page/chess/index.vue"),
//     },
//     {
//       path:"gomoku",
//       component:()=> import("@/views/happyLearning/page/chess/cpns/gomoku.vue")
//     },

//     {
//       path:"book",
//       component:()=> import("@/views/happyLearning/page/book/index.vue")
//     },
//     {
//       path:"painting",
//       component:()=> import("@/views/happyLearning/page/painting/index.vue")
//     }
//   ]
// },

{
  path: "/chartTest",
  component: () => import("@/views/chart/index.vue")
}, {
  path: "/book",
  component: () => import("@/views/book/book.vue")
}];
const router = createRouter({
  history: createWebHashHistory(),
  // scrollBehavior:()=>({y:0}),
  routes
});
router.beforeEach(async (to, from, next) => {
  const storeUser = UseStoreUser();
  const storeLogin = UsestoreLogin();
  const token = localStorage.getItem("project-token");
  if (token) {
    let {
      name
    } = JSON.parse(localStorage.getItem("project-userInfo"));
    let res = await storeLogin.requestUserExistAction(name); // 验证账号是否存在
    if (res) {
      let res = await storeLogin.requestRepeatLoginAction(); //  验证用户账号是否重复登录
      if (res) {
        // 已被其它人登录
        next("/home");
      } else {
        // 没有
        if (to.fullPath !== "/user/personage/information") {
          let kg = true;
          for (const item of storeUser.requiredUserInfo) {
            for (const key in item) {
              if (!storeUser.userInfo[key]) {
                kg = false; // 信息没填写完
                storeUser.userInfoVisible = true;
                next("/user/personage/information");
              }
            }
          }
          if (kg) {
            // 信息都填写完了
            next();
          }
        } else {
          next(); // 在基本当前页面刷新
        }
      }
    } else {
      localStorage.removeItem("project-token");
      localStorage.removeItem("project-userInfo");
      storeLogin.token = "";
      ElMessage({
        message: "账号异常，重新登录",
        type: "error"
      });
      next("/home");
    }
  } else {
    // 没有登录情况，通行
    if (localStorage.getItem("project-token") == null && to.fullPath != "/home") {
      ElMessage({
        message: `请先登录~`,
        type: "error"
      });
      next("/home");
    } else {
      next();
    }
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
export default router;