import LysRequest from "../request/index"

// 分页查询用户搜索记录
export const requestSearchUserRecord = (data) => {
  return LysRequest.request({
    url: `/search/searchUserRecord`,
    method: "post",
    data
  })
}

// 模糊搜索
export const requestSearchList = (data) => {
  return LysRequest.request({
    url: `/search/comprehensiveSearch`,
    method: "post",
    data
  })
}

//删除用户搜索记录通过记录id
export const requestSearchListDelete = (data) => {
  return LysRequest.request({
    url: `/search/deleteUserRecord`,
    method: "post",
    data
  })
}

// 清空用户搜索记录通过用户id
export const requestSearchListDeleteAll = (data) => {
  return LysRequest.request({
    url: `/search/clearUserRecord`,
    method: "post",
    data
  })
}


// 支付宝支付接口
export const requestZfbPay = (data) => {
  return LysRequest.request({
    url: `/pay/aliPay`,
    method: "post",
    data
  })
}

// 微信支付接口
export const requestWxPay = (data) => {
  return LysRequest.request({
    url: `/pay/wxPay`,
    method: "post",
    data
  })
}

// 余额支付接口
export const requestBalancePay = (data) => {
  return LysRequest.request({
    url: `/pay/balancePay`,
    method: "post",
    data
  })
}

// 查询订单状态
export const requestPayStatus = (orderNumber) => {
  return LysRequest.request({
    url: `/pay/status/${orderNumber}`,
    method: "post"
  })
}

// 优惠劵
export const requestCouponList = (data) => {
  return LysRequest.request({
    url: `/userVipCoupons/searchUserVipCoupons`,
    method: "post",
    data
  })
}

// 获取年级和学科 （题库）
export const requestGradesAndSubjects = () => {
  return LysRequest.request({
    url: "/selfQuestion/getGradesAndSubjects",
    method: "get"
  })
}

// 获取年级
export const requestGrades = () => {
  return LysRequest.request({
    url: "/selfQuestion/getGrades",
    method: "get"
  })
}

// 获取年级段
export const requestGradeSection = (gradeId) => {
  return LysRequest.request({
    url: `/selfQuestion/getGradeSection/${gradeId}`,
    method: "get"
  })
}

// 获取学科
export const requestSubjects = (gradeId) => {
  return LysRequest.request({
    url: `/selfQuestion/getSubjects/${gradeId}`,
    method: "get"
  })
}

// 按搜索条件查询公式模版
export const requestFormulaList = (data) => {
  return LysRequest.request({
    url: `/formula/queryByTemplate`,
    method: "post",
    data
  })
}
// 用户收藏词条-添加
export const requestFormulaCollectAdd = (data) => {
  return LysRequest.request({
    url: `/formula/favoriteLatexFormula`,
    method: "post",
    data
  })
}

// 用户收藏词条-删除
export const requestFormulaCollectDel = (data) => {
  return LysRequest.request({
    url: `/formula/cancelFavoriteLatexFormula`,
    method: "post",
    data
  })
}



// 查询词条
export const requestEntryList = (data) => {
  return LysRequest.request({
    url: `/entry/searchEntryPage`,
    method: "post",
    data
  })
}

// 用户收藏词条-添加
export const requestEntryCollectAdd = (data) => {
  return LysRequest.request({
    url: `/entry/add/${data.userId}/${data.entryId}`,
    method: "post"
  })
}

// 用户收藏词条-删除
export const requestEntryCollectDel = (data) => {
  return LysRequest.request({
    url: `/entry/remove/${data.userId}/${data.entryId}`,
    method: "post"
  })
}

// 查询特殊符号
export const requestSymbolList = () => {
  return LysRequest.request({
    url: `/formula/querySpecialSymbols`,
    method: "get"
  })
}
