import { defineStore } from "pinia"
import {
  requestUserBooks,
  requestUserAudioBook,
  requestBookAudio,
  requestDeleteAudio,
  requestUserSubjectName,
  requestUserNoteBook,
  requestBookNote,
  reqUserIdCourse,
  requestEditUserInfo,
  requestUserInfo,
  requestBookRecord,
  requestSelectBookByUserId,
  requestAllSubjectName,
  requestUserVipRead,
  requestAddressList,
  requestVagueSchoolName,
  requestGradesAndSubjects,
  requestVideoNoteList,
  requestCollectTopic,
  requestTopicNoteTopic,
  requestTopicNoteTopicDel,
  requestvideoCollectSubject,
  requestvideoCollect,
  requestBookCollectSubject,
  requestBookCollect,
  requestTopicWrongSubject,
  requestTopicWrong,
  requestTopicWrongDel,
  requestUserBookRecord,
  requestGetUserBookRecord,
  requestUserBookRecordList,
  requestUserWhetherSignIn,
  requestUserSignIn,
  requestCollectionFavorite,
  requestCollectionFavoriteList,
  requestCollectionFavoriteDel,
  requestCollectionFavoriteAddMove,
  requestCollectionFavoriteEdit,
  requestCollectionFavoriteDeleteMove,
  requestUserNoteList,
  requestUserNoteUpdate,
  requestUserNoteInfo,
  requestUserNoteDel,
  requestUserTopicRecords,
  requestUserHomeWork,
  requestUserHomeWorkDetail,
  requestUserHomeWorkSubmit,
  reqUserCoursesShop,
  reqCoursesShopGrades,
  reqCoursesShopGradeSections,
  reqCoursesShopSubject,
  reqCoursesShopSku,
  reqCoursesShopOrder,
  reqUserCourses,
  reqUserCoursesDetails,
  reqUserCoursesSign,
  reqUserCoursesUsageRecord,
  reqUserDayCourses,
  reqUserTaskDetails,
  reqCheckUserCourse,
  reqSearchPreviewDay,
  reqPreviewComplete,
  reqUserBroadcast,
  reqNoteCatalogByBookId,
  reqBookNoteList,
  reqBookNoteDel,
  reqNoteSubject
} from "@/service/modular/user"
import { ElMessage } from "element-plus"

const UseStoreUser = defineStore("storeUser", {
  state: () => ({
    userInfoStatus: true,
    userInfoVisible: false,
    bookCount: 0,
    userBooks: [],
    userBookSubject: [],
    userAudioBook: [],
    audioAll: [],
    userNote: [],
    BookNoteContent: [],
    userTimetable: [],
    userInfo: {},
    requiredUserInfo: [
      {
        realName: "姓名"
      },
      {
        mobile: "电话"
      },
      {
        address: "地址"
      },
      {
        schoolName: "学校"
      },
      {
        gradeName: "年级"
      }
    ],

    // 笔记中心
    note: { 
      subjectActive:"全科",
      subjectList:[],
      noteVideo: {
        list: [],
        total: 0
      },
      noteTopic: { 
        list: [],
        total: 0
      },
      noteUser: {
        list: [],
        activeNote: {}
      },
      noteBooks: {
        catalog:[],
        noteActiveList:[]
      } 
    },

    // 收藏中心
    collect: {
      collectTopic: {
        subjectName: [],
        list: [],
        total: 0
      },
      collectVideo: {
        subjectName: [],
        list: [],
        total: 0
      },
      collectBook: {
        subjectName: [],
        list: [],
        total: 0
      },
      folder:{
        list:[],
        total: 0
      },
      folderCollectActive: []
    },

    // 错题中心
    wrong: {
      wrongTopic: {
        subjectName: [],
        list: [],
        total: 0
      }
    },

    // 学习记录
    records: {
      recordsTopic: {
        list: [],
        total: 0
      }
    },

    //家庭作业
    job:{
      list:[],
      total: 0,
      jobDetail:{}
    },

    //线下课程
    coursesShop:{
      list:[],
      total: 0,
      grades:[], 
      gradeSections:[],
      subject:[],
      sku:[],
      order:{
        list:[],
        total:0
      },
      coursesList:[],
      coursesDetails:[],
      taskDetails:[],
      usageRecord:{},
      previewDetails:[],

      userBroadcast:[] // 用户广播信息
    }

  }),
  actions: {
    // 书籍最近记录
    async requestBookRecordActions(parameter) {
      let res = await requestBookRecord(parameter)
      console.log(res)
    },

    // 最近书籍
    requestSelectBookByUserIdActive(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestSelectBookByUserId(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 获取用户信息
    async requestUserInfoActions() {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserInfo()
        if (res?.code == 200) {
          this.userInfo = res.data
          resolve(true)
        }
      })
    },

    // 修改用户信息
    requestEditUserInfoAction(userInfo) {
      return new Promise(async (resolve, reject) => {
        let res = await requestEditUserInfo(userInfo)
        ElMessage({ message: "修改成功", type: "success" })
        if (res?.code == 200) {
          this.requestUserInfoActions().then(() => {
            resolve(true)
          })
        }
      })
    },

    // 获取用户 学习课程
    async requestUserBooksAction(info) {
      let { data } = await requestUserBooks(info)
      this.bookCount = data.length
      this.userBooks = data
    },

    // 获取用户 录音的书籍
    async requestUserAudioBookAction(userId) {
      let res = await requestUserAudioBook(userId)
      this.userAudioBook = res.data
    },

    //根据书id获取所有音频
    async requestBookAudioAction(bookId) {
      let data = {
        bookId: bookId,
        userId: parseInt(localStorage.getItem("project-userInfo"))?.id
      }
      let res = await requestBookAudio(data)
      
      this.audioAll = res.data
    },

    //根据音频id删除音频
    requestDeleteAudioAction(id) {
      return new Promise(async (resolve, reject) => {
        let res = await requestDeleteAudio(id)
        if (res?.code == 200) {
          ElMessage({ message: res.data, type: "success" })
          resolve(true)
        }
      })
    },

    //获取用户课程的科目
    async requestUserSubjectNameAction(info) {
      return new Promise(async (resolve, reject) => {
        let { data } = await requestUserSubjectName(info)
        this.userBookSubject = data
        resolve(true)
      })
    },

    // 根据科目获取笔记封面
    async requestUserNoteBookAction(parameter) {
      let { data } = await requestUserNoteBook(parameter)
      this.userNote = data
    },

    // 根据书籍ID和用户ID获取笔记
    async requestBookNoteAction(info) {
      let { data } = await requestBookNote(info)
      this.BookNoteContent = data
    },

    reqUserIdCourseAction(info) {
      return new Promise(async (resolve, reject) => {
        let res = await reqUserIdCourse(info)
        if (res?.code == 200) {
          this.userTimetable = res.data
          resolve(true)
        }
      })
    },

    // 获取所有笔记科目
    requestAllSubjectNameAction() {
      return new Promise(async (resolve, reject) => {
        let res = await requestAllSubjectName()
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 查询是否阅读达到上限
    requestUserVipReadAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserVipRead(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 获取上海区域列表
    requestAddressListAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestAddressList(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 模糊查询学校
    requestVagueSchoolNameAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestVagueSchoolName(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 获取学段和年级
    requestGradesAndSubjectsAction() {
      return new Promise(async (resolve, reject) => {
        let res = await requestGradesAndSubjects()
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 中心收藏--- 智库 -- 题目列表
    async requestCollectTopicAction(parameter) {
      let res = await requestCollectTopic(parameter)
      if (res?.code == 200) {
        this.collect.collectTopic.list = res.data.list
        this.collect.collectTopic.total = res.data.total
      }
    },

   

    // 中心笔记--- 视频 -- 视频列表
    async requestVideoNoteListAction(parameter) {
      let res = await requestVideoNoteList(parameter)
      if (res?.code == 200) {
        this.note.noteVideo.list = res.data
      }
    },

   

    // 中心笔记--- 智库 -- 题目列表
    async requestTopicNoteTopicAction(parameter) {
      let res = await requestTopicNoteTopic(parameter)
      if (res?.code == 200) {
        this.note.noteTopic.list = res.data.list
        this.note.noteTopic.total = res.data.total
      }
    },

    // 中心笔记--- 智库 -- 题目列表
    async requestTopicNoteTopicDelAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestTopicNoteTopicDel(parameter)
        if (res?.code == 200) {
          resolve(true) 
        }
      })
    },

    // 中心收藏--- 视频 -- 科目列表
    async requestvideoCollectSubjectAction(userId) {
      let res = await requestvideoCollectSubject(userId)
      if (res?.code == 200) {
        this.collect.collectVideo.subjectName = res.data
      }
    },

    // 中心收藏--- 视频 -- 视频列表
    async requestvideoCollectAction(parameter) {
      let res = await requestvideoCollect(parameter)
      if (res?.code == 200) {
        this.collect.collectVideo.list = res.data.list
        this.collect.collectVideo.total = res.data.total
      }
    },

    // 中心收藏--- 课本 -- 科目列表
    async requestBookCollectSubjectAction(userId) {
      let res = await requestBookCollectSubject(userId)
      if (res?.code == 200) {
        this.collect.collectBook.subjectName = res.data
      }
    },

    // 中心收藏--- 课本 -- 收藏列表
    async requestBookCollectAction(parameter) {
      let res = await requestBookCollect(parameter)
      if (res?.code == 200) {
        
        this.collect.collectBook.list = res.data.records
        this.collect.collectBook.total = res.data.total
      }
    },

    // 中心错题--- 智库 -- 科目列表
    async requestTopicWrongSubjectAction(userId) {
      let res = await requestTopicWrongSubject(userId)
      if (res?.code == 200) {
        this.wrong.wrongTopic.subjectName = res.data
      }
    },

    // 中心错题--- 智库 -- 题目列表
    async requestTopicWrongAction(parameter) {
      let res = await requestTopicWrong(parameter)
      if (res?.code == 200) {
        this.wrong.wrongTopic.list = res.data.list
        this.wrong.wrongTopic.total = res.data.total
      }
    },

    // 中心错题--- 智库 -- 题目删除
    requestTopicWrongDelAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestTopicWrongDel(parameter)
        if (res?.code == 200) {
          ElMessage({ message: "删除成功", type: "success" })
          resolve(true)
        }
      })
    },

    //记录用户阅读书籍
    async requestUserBookRecordAction(parameter) {
      let res = await requestUserBookRecord(parameter)
      if (res?.code == 200) {
        console.log(res)
      }
    },

    // 获取用户一条最近阅读记录
    async requestGetUserBookRecordAction(parameter) {
      let res = await requestGetUserBookRecord(parameter)    
      if (res?.code == 200 ) {
        return res.data
      }
    },

    // 获取书籍阅读记录列表
    async requestUserBookRecordListAction(parameter) {
      let res = await requestUserBookRecordList(parameter)
      if (res?.code == 200) {
        this.UserBookRecordList = res.data
      }
    },

    // 用户是否签到
    async requestUserWhetherSignInAction(userId) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserWhetherSignIn(userId)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 用户签到
    async requestUserSignInAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserSignIn(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 新建收藏夹
    async requestCollectionFavoriteAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestCollectionFavorite(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },
    // 编辑收藏夹
    async requestCollectionFavoriteEditAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestCollectionFavoriteEdit(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 根据用户Id和类型获取收藏夹
    async requestCollectionFavoriteListActioon(parameter) {
      let res = await requestCollectionFavoriteList(parameter)
      if (res?.code == 200) {
        this.collect.folder.list = res.data.records
        this.collect.folder.total = res.data.total
      }
    },

    // 删除收藏夹
    requestCollectionFavoriteDelAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestCollectionFavoriteDel(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },

    // 将数据添加收藏夹
    requestCollectionFavoriteAddMoveAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestCollectionFavoriteAddMove(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },

    // 收藏文件夹里数据
    async requestFolderCollectAction(parameter) {
      let res = await requestCollectTopic(parameter)
      if (res?.code == 200) {
        this.collect.folderCollectActive = res.data.list
      }
    },

    // 将数据添加到收藏夹
    requestCollectionFavoriteDeleteMoveActive(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestCollectionFavoriteDeleteMove(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },

    // 笔记中心 - 个人笔记 - 查询列表
    async requestUserNoteListActive(parameter) {
      let res = await requestUserNoteList(parameter)
      if (res?.code == 200) {
        this.note.noteUser.list = res.data.records
      }
    },

    // 笔记中心 - 个人笔记 - 保存修改
    async requestUserNoteUpdateActive(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserNoteUpdate(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 笔记中心 - 个人笔记 - 查询列表
    async requestUserNoteInfoActive(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserNoteInfo(parameter)
        if (res?.code == 200) {
          this.note.noteUser.activeNote = res.data
          resolve(true)
        }
      })
    },

    // 笔记中心 - 个人笔记 - 删除
    requestUserNoteDelActive(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserNoteDel(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },
     // 笔记中心 - 书籍笔记 - 查询目录
     async reqNoteCatalogByBookIdActive(parameter){
      let res = await reqNoteCatalogByBookId(parameter)
      if (res?.code == 200) {
        this.note.noteBooks.catalog = res.data
      }
     },

     // 笔记中心 - 书籍笔记 - 笔记列表
     async reqBookNoteListActive(parameter){
      let res = await reqBookNoteList(parameter)
      if (res?.code == 200) {
        this.note.noteBooks.noteActiveList = res.data
        return true
      }
     },

     // 笔记中心 - 书籍笔记 - 删除笔记 
     async reqBookNoteDelActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqBookNoteDel(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
     },

     // 笔记中心 - 查询科目
     async reqNoteSubjectActive(parameter){
      let res = await reqNoteSubject(parameter)
      if (res?.code == 200) {
        this.note.subjectList = res.data
        return true
      }
     },
     



    // 学习记录 - 智库
    async requestUserTopicRecordsActive(parameter) {
      let res = await requestUserTopicRecords(parameter)
      if (res?.code == 200) {
        this.records.recordsTopic.list = res.data.list
        this.records.recordsTopic.total = res.data.total
      }
    },

    // 家庭作业
    async requestUserHomeWorkActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await requestUserHomeWork(parameter)
        if (res?.code == 200) {
          this.job.list = res.data.list
          this.job.total = res.data.total
          resolve(true)
        }
      })
    },

    // 家庭作业 id查询答题情况
    async requestUserHomeWorkDetailActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await requestUserHomeWorkDetail(parameter)
        if (res?.code == 200) {
          
          this.job.jobDetail = res.data
          resolve(true)
        }
      })
    },

    // 家庭作业提交
    requestUserHomeWorkSubmitActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await requestUserHomeWorkSubmit(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },

    // 分页查询线下课程价格
    async reqUserCoursesShopActive(parameter){
      let res = await reqUserCoursesShop(parameter)
      if (res?.code == 200) {
        this.coursesShop.list = res.data.records
        this.coursesShop.total = res.data.total
      }
    },

    // 线下课程-筛选-年级段
    async reqCoursesShopGradesActive(){
      let res = await reqCoursesShopGrades()
      if (res?.code == 200) {
        this.coursesShop.grades = res.data
      }
    },

    // 线下课程-筛选-年级
    async reqCoursesShopGradeSectionsActive(gradeId){
      let res = await reqCoursesShopGradeSections(gradeId)
      if (res?.code == 200) {
        this.coursesShop.gradeSections = res.data
      }
    },

    // 线下课程-筛选-学科
    async reqCoursesShopSubjectActive(parameter){
      let res = await reqCoursesShopSubject(parameter)
      if (res?.code == 200) {
        this.coursesShop.subject = res.data
      }
    },

    // 线下课程-筛选-套餐
    async reqCoursesShopSkuActive(parameter){
      let res = await reqCoursesShopSku(parameter)
      if (res?.code == 200) {
        this.coursesShop.sku = res.data
      }
    },

    // 线下课程-订单记录
    async reqCoursesShopOrderActive(parameter){
      let res = await reqCoursesShopOrder(parameter)
      if (res?.code == 200) {
        console.log(res.data.records);
        this.coursesShop.order.list = res.data.records
        this.coursesShop.order.total = res.data.total
      }
    },

    // 查询用户月份课程
    async reqUserCoursesActive(parameter){
      let res = await reqUserCourses(parameter)
      if (res?.code == 200) {
        
        this.coursesShop.coursesList = res.data
      }
    },

    // 查询用户当天课程状态
     reqUserDayCoursesActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await  reqUserDayCourses(parameter)
        if(res.code == 200){
          resolve(res.data)
        }
      })
    },

    // 查询用户当天课程
    async reqUserCoursesDetailsActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqUserCoursesDetails(parameter)
        if (res?.code == 200) {
          this.coursesShop.coursesDetails = res.data
          resolve(true)
        }
      })
    },

    // 查询用户当天作业
    async reqUserTaskDetailsActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqUserTaskDetails(parameter)
        if (res?.code == 200) {
          this.coursesShop.taskDetails = res.data
          resolve(true)
        }
      })
    },

    // 课程签到
    async reqUserCoursesSignActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqUserCoursesSign(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },

     // 查询用户课程使用记录
     async reqUserCoursesUsageRecordActive(parameter){
        let res = await reqUserCoursesUsageRecord(parameter)
        if (res?.code == 200) {
          this.coursesShop.usageRecord = res.data
        }
    },

    // 检查是否不存在未签到课程
     reqCheckUserCourseActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqCheckUserCourse(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    // 查询当天预习列表
    reqSearchPreviewDayActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqSearchPreviewDay(parameter)
        if (res?.code == 200) {
          this.coursesShop.previewDetails = res.data
          resolve(true)
        }
      })
    },

    //完成预习
    reqPreviewCompleteActive(parameter){
      return new Promise(async (resolve, reject) => {
        let res = await reqPreviewComplete(parameter)
        if (res?.code == 200) {
          resolve(true)
        }
      })
    },

    // 用户广播信息
    async reqUserBroadcastActive(parameter){
      let res = await reqUserBroadcast(parameter)
      if (res?.code == 200) {
        this.userBroadcast = res.data
      }
    }

  }
})
export default UseStoreUser
