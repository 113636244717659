import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { reactive, ref } from "vue";
export default {
  __name: 'index',
  props: {
    width: {
      type: [String, Number],
      default: 444
    },
    height: {
      type: [String, Number],
      default: 430
    }
  },
  emits: ["resizingEmit"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    let select = ref(true);
    let onOff = ref(false);
    // 拖拽的配置

    const dragData = reactive({
      x: 0,
      y: 80,
      h: 201,
      w: 570,
      active: true
    });
    // 拖拽的事件
    const print = val => {
      console.log(val);
    };
    const resizingFn = obj => {
      emit("resizingEmit", obj.h);
    };
    __expose({
      select,
      onOff,
      dragData
    });
    return (_ctx, _cache) => {
      const _component_Vue3DraggableResizable = _resolveComponent("Vue3DraggableResizable");
      return _unref(onOff) ? (_openBlock(), _createBlock(_component_Vue3DraggableResizable, {
        key: 0,
        classNameDraggable: "draggable",
        initW: props.width,
        initH: props.height,
        minW: props.width,
        minH: props.height,
        x: dragData.x,
        "onUpdate:x": _cache[0] || (_cache[0] = $event => dragData.x = $event),
        y: dragData.y,
        "onUpdate:y": _cache[1] || (_cache[1] = $event => dragData.y = $event),
        w: dragData.w,
        "onUpdate:w": _cache[2] || (_cache[2] = $event => dragData.w = $event),
        h: dragData.h,
        "onUpdate:h": _cache[3] || (_cache[3] = $event => dragData.h = $event),
        active: dragData.active,
        "onUpdate:active": _cache[4] || (_cache[4] = $event => dragData.active = $event),
        draggable: _unref(select),
        resizable: true,
        parent: true,
        onActivated: _cache[5] || (_cache[5] = $event => print('activated')),
        onDeactivated: _cache[6] || (_cache[6] = $event => print('deactivated')),
        onDragStart: _cache[7] || (_cache[7] = $event => print('drag-start')),
        onResizeStart: _cache[8] || (_cache[8] = $event => print('resize-start')),
        onDragging: _cache[9] || (_cache[9] = $event => print('dragging')),
        onResizing: resizingFn,
        onDragEnd: _cache[10] || (_cache[10] = $event => print('drag-end')),
        onResizeEnd: _cache[11] || (_cache[11] = $event => print('resize-end'))
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "noteContent")]),
        _: 3
      }, 8, ["initW", "initH", "minW", "minH", "x", "y", "w", "h", "active", "draggable"])) : _createCommentVNode("", true);
    };
  }
};